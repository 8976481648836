import { Country, countrySchema } from './useCountry.type'
import { composeQuery } from './utils/factory'
import { Endpoint } from './utils/url'

export const useCountriesQuery = composeQuery(countrySchema.array(), [
  Endpoint.Countries,
])

export const getCountry = (
  country: Partial<Country>,
  countries: Country[] | undefined
): Country => {
  return (
    countries?.find(
      (countryMeta) =>
        countryMeta.name === country.name ||
        countryMeta.isoCode === country.isoCode
    ) ?? {
      isoCode: 'XX',
      name: 'Country',
    }
  )
}
