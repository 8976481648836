<script setup lang="ts">
  import { useVModel } from '@vueuse/core'
  import { computed } from 'vue'
  import { useCountriesQuery } from '@/api/useCountry'
  import { SelectOption } from '@/modules/base/components/baseSelect'
  import BaseSelect from '@/modules/base/components/BaseSelect.vue'
  import { composeCompareAlphanumeric } from '@/modules/base/utils/array'

  const props = defineProps<{
    modelValue?: string | null
  }>()

  const emit = defineEmits<{
    (event: 'update:modelValue', value: string): void
  }>()

  const { data: countryList } = useCountriesQuery()
  const options = computed<SelectOption<string>[]>(
    () =>
      countryList.value
        ?.map(({ isoCode, name }) => ({
          key: isoCode,
          title: name,
        }))
        .sort(composeCompareAlphanumeric(({ title }) => title)) ?? []
  )

  const model = useVModel(props, 'modelValue', emit)

  // Germany, Austria, Switzerland, USA, China
  const topCountries = ['DE', 'AT', 'CH', 'US', 'CN']
</script>

<template>
  <BaseSelect
    v-model="model"
    border-color="gray-600"
    data-testid="country-select"
    :options
    :placeholder="$t('global.country.placeholder')"
    text-align="left"
    :top-keys="topCountries"
  />
</template>
